import type {StaticImageData} from 'next/legacy/image';
import type {ReactElement} from 'react';

import CupImage from './assets/cup.webp';
import CupImageMobile from './assets/cup-mobile.webp';
import MedalImage from './assets/medal.webp';
import MedalImageMobile from './assets/medal-mobile.webp';
import MoneyImage from './assets/money.webp';
import MoneyImageMobile from './assets/money-mobile.webp';

export interface ItemRating {
	id: number;
	imageDesktop: StaticImageData;
	imageMobile: StaticImageData;
	title: string | ReactElement;
}

export const data: ItemRating[] = [
	{
		id: 0,
		imageDesktop: CupImage,
		imageMobile: CupImageMobile,
		title: <>&#8470;&nbsp;1&nbsp;в рейтинге унифицированных коммуникаций Cnews</>,
	},
	{
		id: 1,
		imageDesktop: MoneyImage,
		imageMobile: MoneyImageMobile,
		title: 'Лучшее решение для цифровой экономики ComNews Awards',
	},
	{
		id: 2,
		imageDesktop: MedalImage,
		imageMobile: MedalImageMobile,
		title: <>Лидер рынка On‑premises решений по&nbsp;версии Json Partners</>,
	},
];

import React from 'react';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import Image from '@/components/common/image/Image';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {Media, MediaContextProvider} from '@/media';

import {data} from './const';
import styles from './Rating.module.css';

export function Rating() {
	return (
		<Section
			isFullWidth
			style={SECTION_STYLES.WITH_BREAKPOINTS}
			direction="column"
			className={styles.root}
		>
			<div className={styles.cards}>
				{data.map(({imageMobile, imageDesktop, title, id}) => (
					<div key={id} className={styles.card}>
						<Typography className={styles.title} variant={VARIANTS.h4}>
							{title}
						</Typography>
						<div className={styles.imageWrapper}>
							<MediaContextProvider>
								<Media lessThan="desktop">
									<Image src={imageMobile} objectFit="contain" alt="Image" />
								</Media>
								<Media at="desktop">
									<Image
										src={imageDesktop}
										alt="Image"
										objectFit="contain"
										width={290}
										height={140}
									/>
								</Media>
								<Media greaterThanOrEqual="desktop_xl">
									<Image
										src={imageDesktop}
										alt="Image"
										objectFit="contain"
										width={392}
										height={200}
									/>
								</Media>
							</MediaContextProvider>
						</div>
					</div>
				))}
			</div>
		</Section>
	);
}

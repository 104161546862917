import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import Image from '@/components/common/image/Image';
import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {TryButton} from '@/components/common/try-button/TryButton';

import mainImg from './assets/main.webp';
import styles from './Promo.module.css';

type PromoProps = {
	id?: string;
};

export function Promo({id}: PromoProps) {
	return (
		<Section style={SECTION_STYLES.WITH_BREAKPOINTS} className={styles.root} sectionId={id}>
			<div className={styles.media} />
			<div className={styles.imgWrapper}>
				<Image
					className={styles.img}
					width={251}
					height={262}
					src={mainImg}
					layout="responsive"
					alt="VK WorkSpace"
				/>
			</div>
			<div className={styles.content}>
				<div>
					<Typography variant={VARIANTS.h1} className={styles.title}>
						Организуйте совместную работу команд с&nbsp;VK&nbsp;WorkSpace
					</Typography>
					<Typography variant={VARIANTS.text} className={styles.desc}>
						Почта, календарь, мессенджер, видеозвонки, диск и&nbsp;документы на&nbsp;одной
						платформе в&nbsp;SaaS-версии или в&nbsp;контуре компании
					</Typography>
				</div>
				<div className={styles.buttons}>
					<div key="try-button" data-button-type="try-button" className={styles.wrapButton}>
						<TryButton className={styles.button} />
					</div>
				</div>
			</div>
		</Section>
	);
}
